.contacts {
  &-item {
    margin-bottom: 40px;

    @include media-breakpoint-down(md){
      margin-bottom: 0;
    }

    &__name {
      font-weight: 400;
      font-size: 14px;
      line-height: 148%;
      color: rgba(255, 255, 255, 0.5);
      margin-bottom: 6px;
    }
    &__value {
      font-weight: 600;
      font-size: 20px;
      line-height: 110%;
      color: #fff;
      text-decoration: none;
      a {
        color: #fff;
        text-decoration: none;
      }
    }
  }

  &__list {
    @include media-breakpoint-down(xl){
      display: grid;
      grid-template-columns: 1fr 1fr;
      row-gap: 30px;
      grid-gap: 30px;
    }
    @include media-breakpoint-down(md){
      grid-template-columns: 1fr;
      margin-bottom: 40px;
    }
  }
}

#map {
  border-radius: 10px;
  overflow: hidden;

  iframe {
    border-radius: 10px;
  }
}