.services {
  overflow: visible;
  &:after {
    position: absolute;
    right: 0;
    top: -50px;
    content: '';
    display: block;
    background: #3D9ED5;
    filter: blur(183px);
    width: 383px;
    height: 181px;
    z-index: 9;
  }
}