.tizer {
  background: url('../img/tizer-bg.png') no-repeat;
  background-size: cover;
  padding: 104px 0;

  .section {
    &__title {
      margin-bottom: 30px;
    }
    &__text {
      margin-bottom: 40px;
    }
  }
}