
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  padding-top: rem(22);
  background: rgba(0, 0, 0, 0.16);
  padding-bottom: rem(22);
  transition: all .3s;

  .burger {
    background: none;
    border: none;
    width: 46px;
    height: 22px;
    margin-left: 40px;
    padding: 0;

    svg {
      display: block;
      width: 100%;
      height: 100%;
    }
  }

  &.fixed {
    background: #1A1A1A;
  }

  &__logo {
    font-size: 18px;
    font-weight: 600;
  }


  .btn {
    @include media-breakpoint-down(sm){
      display: none;
    }
  }

  &__menu-col {
    display: flex;
    justify-content: center;
  }

  a {
    color: #fff;
    text-decoration: none;
  }

  nav {
    @include media-breakpoint-down(lg){
      display: none;
    }
  }


  ul {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;

    li {
      list-style-type: none;
      margin: 0 20px;

      a {
        display: block;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.mobile-menu {
  visibility: hidden;
  position: fixed;
  top: 0;
  right: -1500px;
  transition: all .4s;
  z-index: 99;
  width: 376px;
  background: #1A1A1A;
  padding: 40px;

  @include media-breakpoint-down(md){
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .btn {
    &-inverse {
      color: #fff;

      &:hover {
        color: #000;
      }
    }
  }

  &__header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
  &__footer {
    .contacts {
      &__list {
        display: flex;
        flex-direction: column;
        grid-gap: 0;
        row-gap: 0;
      }
      &-item {
        margin-bottom: 30px;
      }
    }
  }

  nav {
    li {
      list-style-type: none;
      margin-bottom: 40px;
      a {
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        color: #fff;
        text-decoration: none;
      }
    }
  }

  .burger-close {
    background: none;
    border: none;
    width: 30px;
    height: 30px;
    padding: 0;
    svg {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  &.active {
    right: 0;
    visibility: visible;
  }
}