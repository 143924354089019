.about {
  p {
    line-height: 148%;
  }

  &__images {
    position: relative;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 34px;

    @include media-breakpoint-down(xl){
      order: 2;
      margin-top: 40px;

    }

    img {
      width: 60%;
      &:last-child {
        position: absolute;
        top: 50%;
        width: 55%;
        right: 45%;
        //top: 150px;
        //right: 340px;
      }
    }
  }

  &__media-block {
    display: flex;
    justify-items: flex-end;
    flex-direction: column;

  }

  &__counter {
    font-weight: 600;
    font-size: 50px;
    line-height: 110%;
    color: #3D9ED5;
    margin-bottom: 4px;

    &-caption {
      font-size: 18px;
    }
  }


  &__counter-block {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    justify-items: flex-end;

    @include media-breakpoint-down(xl){
      order: 1;
      margin-top: 40px;
    }
    div {
      justify-self: flex-end;
      align-self: flex-end;

      @include media-breakpoint-down(xl){
        align-self: flex-start;
        justify-self: flex-start;
      }
    }
  }


}