.reviews {
  overflow: visible;
  &:before {
    position: absolute;
    left: 0px;
    top: 0;
    content: '';
    display: block;
    background: #208C93;
    filter: blur(283px);
    width: 283px;
    height: 321px;
    z-index: 2;
  }
  &-item {
    background: linear-gradient(180deg, #171717 0%, rgba(23, 23, 23, 0.2) 100%);
    border-radius: 10px;
    padding: 40px;

    @include media-breakpoint-down(md){
      padding: 20px;
    }

    &__title {
      font-weight: 600;
      font-size: 20px;
      line-height: 110%;
      margin-bottom: 20px;
    }
  }
}



.swiper {
  z-index: 3;
  &#reviews {
    padding-bottom: 50px;
  }
  .swiper-pagination {
    bottom: 0;

    &-bullet {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.3);
      margin-right: 16px;

      &-active {
        background: #fff;
      }
    }
  }
}