.footer {
  background: #000;
  padding: 10px 0;



  color: rgba(255, 255, 255, 0.4);
  font-size: 14px;
  line-height: 17px;
  @include media-breakpoint-down(md){
    text-align: center;
  }
  a {
    color: rgba(255, 255, 255, 0.4);
    text-decoration: none;
    white-space: nowrap;
    margin-right: 40px;
    display: block;
    &:hover {
      color: #fff;
    }
    @include media-breakpoint-down(lg){
      margin-bottom: 20px;

    }
    @include media-breakpoint-down(md){
      text-align: center;
    }
  }
}