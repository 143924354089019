.works {

  &-item {
    margin-bottom: 30px;
    overflow: hidden;
    border-radius: 10px;
    a {
      display: block;
      overflow: hidden;
      border-radius: 10px;
      -webkit-mask: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
      &:hover {
        img {
          transform: scale(1.1);
          overflow: hidden;
          border-radius: 10px;
        }
      }
    }
    img {
      width: 100%;
      transition: all .4s;

    }
  }
  &__row-one {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 30px;
    grid-gap: 30px;
  }
  &__row-two {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 30px;
    grid-gap: 30px;
  }
}

#works {
  padding-bottom: 50px;
  .swiper-slide {
    img {
      width: 100% ;
    }
  }
}