h1, h2, h3, h4, h5, h6, ul {
    margin: 0;
    padding: 0;
}


body {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-size: 16px;
    background: #1A1A1A;
    color: #fff;

    &.hidden {
        overflow: hidden;
    }

    @include media-breakpoint-down(md) {
        //overflow: hidden;
        //width: 100%;
        //height: auto;
        font-size: 14px;
    }

    .container {
        max-width: 100%;
        width: 1320px;
        padding: 0 40px;

        @include media-breakpoint-down(md) {
            padding: 0 24px;
        }
    }
}

body.calcus-widget {
    background: black;
}

.calc-frow {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.calc-tooltip {
    width: 14px;
    height: 14px;
    content: '';
    background: url('../img/quest.svg') no-repeat;
    background-size: contain;
    color: transparent;
    display: block;
    margin-left: 10px;
}

.calc-input {
    width: 100%;
}

.calc-input-desc {
    width: 100%;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
}

.calc-error-message {
    position: absolute;
    color: red;
    font-size: 12px;
}

.calc-frow select, .calc-frow input {
    border: 1px solid #3D9ED5;
    box-shadow: 0px 0px 10px #3D9ED4;
    border-radius: 10px;
    padding: 12px 20px;
    appearance: none;
    background: none;
    width: 100%;
    color: #fff;
}

.calc-frow select {
    background: url('../img/select-arrows.svg') center right 20px no-repeat;
}


.calc-fright {
    display: flex;
    align-items: center;
    grid-gap: 20px;
}

.calc-frow .calc-fleft {
    font-weight: 400;
    font-size: 16px;
    display: flex;
    align-items: center;
}
.calc-frow .calc-fleft,
.calc-frow .calc-fright {
    width: 100%;
}

.section {
    padding: 160px 0 120px;
    overflow: hidden;
    max-width: 100%;
    position: relative;

    @include media-breakpoint-down(lg){
        padding: 90px 0 60px;
    }

    @include media-breakpoint-down(md) {
        overflow: hidden !important;
    }

    &__title {
        font-weight: 700;
        font-size: 50px;
        line-height: 110%;
        margin-bottom: 60px;

        span {
            color: #3D9ED5;
        }

        @include media-breakpoint-down(md) {
            font-size: 40px;
            line-height: 110%;
        }

        @include media-breakpoint-down(sm) {
            font-size: 26px;
            line-height: 110%;
        }
    }

    &__placeholder {
        color: rgba(15, 15, 15, 0.3);
        font-size: 200px;
        font-weight: 700;
        text-transform: uppercase;
        white-space: nowrap;
        line-height: 1;
        z-index: -1;
        position: absolute;
        left: 0;
        margin-top: -70px;


        @include media-breakpoint-down(lg){
            margin-top: -40px;
            font-size: 110px;
        }
    }
}



.btn {
    padding: 10px 22px;
    border-radius: 100px;
    font-weight: 500;
    background: linear-gradient(95.04deg, #3D9ED4 28.35%, #6EC2F0 91.93%);
    box-shadow: 0px 0px 30px #3D9ED5;
    border: none;

    &-primary {
        padding: 14px 26px;
        font-size: 16px;
        line-height: 19px;
        font-weight: 500;

        &:hover {
            text-decoration: underline;
        }
    }
    //border-radius: 100px;

    &-inverse {
        border: 1px solid #fff;
        box-shadow: none;
        background: none;

        &:hover {
            background: #fff;
            color: #000;
        }
    }
}


.accordion {
    &-item {
        background: none;
        border: none;

        &:before {
            background: rgba(61, 158, 212, 0.5);
            box-shadow: 0px 0px 10px #3D9ED5;
            width: 100%;
            height: 1px;
            content: '';
            display: block;
        }
    }

    &-header {

        background: none;

    }

    &-button {
        padding: 28px 0;
        background: none;
        font-weight: 600;
        font-size: 20px;
        line-height: 36px;
        color: #fff;
        border: none;
        box-shadow: none;
        display: flex;
        justify-content: space-between;
        align-items: center;

        flex-shrink: 0;
        white-space: nowrap;

        @include media-breakpoint-down(md){
            font-size: 18px;
            line-height: 20px;
            font-weight: 500;
        }

        &__caption {
            width: 100%;
            display: flex;
            justify-content: center;
            font-weight: 500;
            font-size: 18px;
            line-height: 110%;
            color: #3D9ED5;

            @include media-breakpoint-down(md){
                display: none;
            }

        }


        &:after {
            content: '+';
            font-weight: 500;
            font-size: 30px;
            line-height: 1;
            background: none;
            color: #fff;
            height: 30px;
            transform: none;
            display: flex;
            justify-content: flex-end;

            @include media-breakpoint-down(md){
                font-size: 25px;
            }
        }

        &:not(.collapsed){
            background: none;
            color: #fff;
            border: none;
            box-shadow: none;

            &:after {
                content: '-';
                background: none;
                transform: none;
                font-size: 30px;
                line-height: 1;
                height: 30px;

                @include media-breakpoint-down(md){
                    font-size: 25px;
                }
            }
        }
    }

    &-body {
        color: #fff;
        padding: 20px 0 40px;
        width: 60%;
    }
}