.process {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 30px;
  row-gap: 40px;
  z-index: 12;
  position: relative;

 &-section {
   //overflow: visible;
   &:after {
     position: absolute;
     right: 0;
     bottom: 0;
     content: '';
     display: block;
     background: #208C93;
     filter: blur(183px);
     width: 183px;
     height: 181px;
     z-index: 9;
   }
 }

  @include media-breakpoint-down(lg){
    display: flex;
    overflow: auto;
    padding: 30px 0;
  }

  &-item {
    position: relative;
    padding-top: 34px;

    @include media-breakpoint-down(lg){
      width: 45vw;
      flex-shrink: 0;
    }

    @include media-breakpoint-down(md){
      width: 70vw;
    }
    &:before {
      background: #3D9ED5;
      box-shadow: 0px 0px 10px #3D9ED5;
      height: 1px;
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% + 30px);
      content: '';
      display: block;
    }
    &:after {
      height: 30px;
      position: absolute;
      width: 30px;
      top: -15px;
      left: 0;
      content: '';
      display: block;
      background: url('../img/sprites/facts-circle.svg') no-repeat;
    }
    &:first-child {
      &:before {
        left: 15px;
      }
    }
    &:nth-child(4n + 1) {
      &:before {
        left: 15px;
      }
    }
    &__title {
      font-weight: 600;
      font-size: 20px;
      line-height: 110%;
      margin-bottom: 12px;
    }

    &__text {
      font-weight: 400;
      font-size: 14px;
      line-height: 148%;
    }
  }
}