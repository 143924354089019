.homemain {
  height: 100vh;
  background: url('/img/main-bg.jpg') bottom  center no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include media-breakpoint-down(lg){
    height: 1200px;
    background: url('/img/main-bg-tablet.jpg') bottom center no-repeat, #000;
    background-size: contain;
  }
  @include media-breakpoint-down(md){
    height: 820px;
  }
  //&__wraper {
  //  position: absolute;
  //  top: 0;
  //  left: 0;
  //  width: 100%;
  //  display: flex;
  //  align-items: center;
  //}

  .container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  &__wrapper {
    position: relative;
    z-index: 3;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @include media-breakpoint-down(lg){
      height: auto;
      padding-top: 185px;
    }
    @include media-breakpoint-down(md){
      padding-top: 50px;
    }
  }

  &__title {
    font-weight: 700;
    font-size: 60px;
    line-height: 110%;
    color: #fff;
    margin-bottom: 30px;

    @include media-breakpoint-down(lg){
      font-size: 50px;
    }

    @include media-breakpoint-down(sm) {
      font-size: 28px;
    }
    span {
      color: #3D9ED5;
    }
  }

  &__text {
    font-weight: 500;
    font-size: 18px;
    line-height: 110%;
    margin-bottom: 40px;
    color: #fff;
  }


  &__facts {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 50px;


    @include media-breakpoint-down(lg){
      justify-content: space-between;
    }

    @include media-breakpoint-down(sm){
      flex-wrap: wrap;
    }

    &_row {
      align-self: flex-end;
      justify-content: flex-end;
      margin-top: auto;

      @include media-breakpoint-down(lg){
        margin-top: 60px;
        justify-content: flex-start;
        width: 100%;
      }

      @include media-breakpoint-down(sm){
        width: auto;
      }
    }

    li {
      margin-left: 100px;
      color: #fff;
      list-style-type: none;
      display: flex;
      align-items: center;
      line-height: 110%;

      @include media-breakpoint-down(lg){
        margin-left: 0;
      }

      @include media-breakpoint-down(md){
        width: 50%;
        margin-bottom: 30px;
        font-size: 14px;
      }

      &:before {
        margin-right: 12px;
        width: 30px;
        height: 30px;
        content: '';
        display: block;
        flex-shrink: 0;
        background: url('../img/sprites/facts-circle.svg') no-repeat;
      }
    }
  }
}

#smoke {
  width: 100%;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  opacity: .3;
}
